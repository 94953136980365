import React, { useState, useEffect } from "react";
import {
  MotifButton,
  MotifSelect,
  MotifOption,
  MotifFormField,
  MotifMessage,
  MotifCard,
  MotifCardHeader,
  MotifCardBody,
  MotifLabel,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import "./MappingForm.scss";

const MappingForm = ({
  responsibleLabel,
  accountableLabel,
  consultedLabel,
  informedLabel,
  othersLabel,
  selectResponsilbleLabel,
  selectAccountableLabel,
  selectConsultedLabel,
  selectInformedLabel,
  selectOthersLabel,
  RACIMapping,
  saveButtonLabel,
  cancelButtonLabel,
  onCancel,
  onSave,
  raciDropdownData,
  initialData,
  pagenName,
  onChange,
}) => {
  const [responsible, setResponsible] = useState("");
  const [accountable, setAccountable] = useState("");
  const [consulted, setConsulted] = useState("");
  const [informed, setInformed] = useState("");
  const [others, setOthers] = useState("");

  // Effect to update form fields when initialData changes
  useEffect(() => {
    if (initialData) {
      setResponsible(initialData.responsible.toString() || "");
      setAccountable(initialData.accountable.toString() || "");
      setConsulted(initialData.consulted.toString() || "");
      setInformed(initialData.informed.toString() || "");
      setOthers(initialData.others.toString() || "");
    }
  }, [initialData]);

  const handleDropdownChange = (field, value) => {
    switch (field) {
      case "responsible":
        setResponsible(value);
        break;
      case "accountable":
        setAccountable(value);
        break;
      case "consulted":
        setConsulted(value);
        break;
      case "informed":
        setInformed(value);
        break;
      case "others":
        setOthers(value);
        break;
      default:
        break;
    }

    if (onChange) {
      onChange({
        responsible: field === "responsible" ? value : responsible,
        accountable: field === "accountable" ? value : accountable,
        consulted: field === "consulted" ? value : consulted,
        informed: field === "informed" ? value : informed,
        others: field === "others" ? value : others,
      });
    }
  };
  const handleSave = () => {
    const formData = {
      responsible: parseInt(responsible, 10),
      accountable: parseInt(accountable, 10),
      consulted: parseInt(consulted, 10),
      informed: parseInt(informed, 10),
      others: parseInt(others, 10),
    };
    onSave(formData);
  };
 

  return (
    <MotifCard className="mapping-card">
      {pagenName === "IROAssessment" && (
        <MotifCardHeader className="mapping-header" variant="alt">
          {RACIMapping}
        </MotifCardHeader>
      )}
      <MotifCardBody>
        <div className="form-row">
          <div className="form-group full-width">
            <MotifMessage>{responsibleLabel}</MotifMessage>
            <MotifFormField>
              <MotifLabel id="select-dma-select-label" position="in">
                {selectResponsilbleLabel}
              </MotifLabel>
              <MotifSelect
                className="session-modal-input"
                ariaLabelledBy="select-dma-select-label"
                labelPosition="in"
                visibleOptions={3}
                value={responsible}
                onChange={(e) => handleDropdownChange("responsible", e)}
              >
                {raciDropdownData.map((item) => (
                  <MotifOption key={item.id} value={item.id.toString()}>
                    {item.name}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
          <div className="form-group full-width">
            <MotifMessage>{accountableLabel}</MotifMessage>
            <MotifFormField>
              <MotifLabel id="select-dma-select-label" position="in">
                {selectAccountableLabel}
              </MotifLabel>
              <MotifSelect
                className="session-modal-input"
                ariaLabelledBy="select-dma-select-label"
                labelPosition="in"
                visibleOptions={3}
                value={accountable}
                onChange={(e) => handleDropdownChange("accountable", e)}
              >
                {raciDropdownData.map((item) => (
                  <MotifOption key={item.id} value={item.id.toString()}>
                    {item.name}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group full-width">
            <MotifMessage>{consultedLabel}</MotifMessage>
            <MotifFormField>
              <MotifLabel id="select-dma-select-label" position="in">
                {selectConsultedLabel}
              </MotifLabel>
              <MotifSelect
                className="session-modal-input"
                ariaLabelledBy="select-dma-select-label"
                labelPosition="in"
                visibleOptions={3}
                value={consulted}
                onChange={(e) => handleDropdownChange("consulted", e)}
              >
                {raciDropdownData.map((item) => (
                  <MotifOption key={item.id} value={item.id.toString()}>
                    {item.name}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
          <div className="form-group full-width">
            <MotifMessage>{informedLabel}</MotifMessage>
            <MotifFormField>
              <MotifLabel id="select-dma-select-label" position="in">
                {selectInformedLabel}
              </MotifLabel>
              <MotifSelect
                className="session-modal-input"
                ariaLabelledBy="select-dma-select-label"
                labelPosition="in"
                visibleOptions={3}
                value={informed}
                onChange={(e) => handleDropdownChange("informed", e)}
              >
                {raciDropdownData.map((item) => (
                  <MotifOption key={item.id} value={item.id.toString()}>
                    {item.name}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
        </div>
        <div className="form-row">
          <div className="form-group full-width">
            <MotifMessage>{othersLabel}</MotifMessage>
            <MotifFormField>
              <MotifLabel id="select-dma-select-label" position="in">
                {selectOthersLabel}
              </MotifLabel>
              <MotifSelect
                className="session-modal-input"
                ariaLabelledBy="select-dma-select-label"
                labelPosition="in"
                visibleOptions={3}
                value={others}
                onChange={(e) => handleDropdownChange("others", e)}
              >
                {raciDropdownData.map((item) => (
                  <MotifOption key={item.id} value={item.id.toString()}>
                    {item.name}
                  </MotifOption>
                ))}
              </MotifSelect>
            </MotifFormField>
          </div>
        </div>
        {pagenName === "IROAssessment" && (
          <div className="form-footer">
            <MotifButton onClick={onCancel} className="modal-cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton onClick={handleSave} className="modal-done-button">
              {saveButtonLabel}
            </MotifButton>
          </div>
        )}
      </MotifCardBody>
    </MotifCard>
  );
};

MappingForm.propTypes = {
  responsibleLabel: PropTypes.string.isRequired,
  accountableLabel: PropTypes.string.isRequired,
  consultedLabel: PropTypes.string.isRequired,
  informedLabel: PropTypes.string.isRequired,
  othersLabel: PropTypes.string.isRequired,
  selectResponsilbleLabel: PropTypes.string.isRequired,
  selectAccountableLabel: PropTypes.string.isRequired,
  selectConsultedLabel: PropTypes.string.isRequired,
  selectInformedLabel: PropTypes.string.isRequired,
  selectOthersLabel: PropTypes.string.isRequired,
  RACIMapping: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  raciDropdownData: PropTypes.array.isRequired,
  initialData: PropTypes.object,
  onChange: PropTypes.func,
  pagenName: PropTypes.string,
};

export default MappingForm;
