import React, { useState, useEffect, useMemo, useCallback } from "react";
import "./ClientOrg.scss";
import Header from "../../components/headers/Header";
import {
  fetchClientsDetails,
  fetchClientTypes,
  createClient,
  updateClient,
  deleteClient,
  clearError,
} from "../../features/slices/ProjectScreens";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { TableCell, Box, IconButton } from "@mui/material";
import { Edit, Delete } from "../../constants/icons/Icons";
import Subheader from "../../components/subheader/Subheader";
import languageData from "../../constants/languages/en/translations.json";
import ClientTable from "../../components/clientTable/ClientTable";
import { useDispatch, useSelector } from "react-redux";
import ClientModal from "../../components/modals/clientModal/ClientModal";
import DeleteModal from "../../components/modals/deleteModal/DeleteModal";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../../components/breadcrumbs/Breadcrumbs";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import ErrorModal from "../../components/modals/error/errorModal";
import { handleError as handleLogoutError } from "../../utils/handleError";

const ClientOrg = () => {
  const isAdmin = true;
  const location = useLocation();
  const { projectId } = useParams();
  const [state, setState] = useState({
    types: [],
    isClientModalOpen: false,
    clientMode: "add",
    clientModalTitle: "",
    selectedClientRow: null,
    clientToDelete: null,
    isDeleteModalOpen: false,
    updateClientList: 0,
    searchClientQuery: "",
    isLoading: false,
    clientData: [],
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token =
    useSelector((state) => state.user.jwtToken) ||
    document.cookie.match(new RegExp(`(^| )authToken=([^;]+)`))?.[2] ||
    sessionStorage.getItem("authToken");
  const projectName = location.state?.projectName;
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setState((prevState) => ({ ...prevState, isLoading: true }));
    try {
      const [clientResponseData, typesResponse] = await Promise.all([
        dispatch(fetchClientsDetails({ projectId, token })).unwrap(),
        dispatch(fetchClientTypes({ token })).unwrap(),
      ]);

      if (typesResponse.success) {
        setState((prevState) => ({ ...prevState, types: typesResponse.data }));
      }

      if (Array.isArray(clientResponseData.data)) {
        const clientsData = clientResponseData.data.map((item) => ({
          ...item,
          typeName: item.clientOrganisationType?.name || "Unknown",
        }));
        setState((prevState) => ({ ...prevState, clientData: clientsData }));
      } else {
        setState((prevState) => ({ ...prevState, clientData: [] }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }, [dispatch, projectId, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData, state.updateClientList]);

  const projectScreenState = useSelector((state) => state.projectDetails);
  const { isError, errorMessage, isGenericError } = projectScreenState;

  const handleEdit = useCallback(
    (row) => {
      setState((prevState) => ({
        ...prevState,
        selectedClientRow: row,
        clientMode: "edit",
        clientModalTitle: t("EditClient"),
        isClientModalOpen: true,
      }));
    },
    [t]
  );

  const handleOpenModal = useCallback(
    (mode) => {
      setState((prevState) => ({
        ...prevState,
        clientMode: mode,
        selectedClientRow: null,
        clientModalTitle: mode === "add" ? t("NewClient") : t("EditClient"),
        isClientModalOpen: true,
      }));
    },
    [t]
  );

  const handleCloseModal = useCallback(() => {
    setState((prevState) => ({ ...prevState, isClientModalOpen: false }));
  }, []);

  const handleSearch = useCallback((event) => {
    setState((prevState) => ({
      ...prevState,
      searchClientQuery: String(event.target.value),
    }));
  }, []);

  const openDeleteModal = useCallback((row) => {
    setState((prevState) => ({
      ...prevState,
      clientToDelete: row,
      isDeleteModalOpen: true,
    }));
  }, []);

  const handleDelete = useCallback(async () => {
    if (!state.clientToDelete) return;
    setState((prevState) => ({ ...prevState, isLoading: true }));

    try {
      const response = await dispatch(
        deleteClient({ clientId: state.clientToDelete.id, projectId, token })
      ).unwrap();

      if (!response.error) {
        setState((prevState) => ({
          ...prevState,
          clientData: prevState.clientData.filter(
            (client) => client.id !== state.clientToDelete.id
          ),
          isDeleteModalOpen: false,
        }));
      } else {
        setState((prevState) => ({ ...prevState, isDeleteModalOpen: false }));
        console.error("Error deleting client:", response.error);
      }
    } catch (error) {
      setState((prevState) => ({ ...prevState, isDeleteModalOpen: false }));
      console.error("Error deleting client:", error);
    } finally {
      setState((prevState) => ({ ...prevState, isLoading: false }));
    }
  }, [dispatch, projectId, state.clientToDelete, token]);

  const handleClientEditUpdateClick = useCallback(
    async (clientData) => {
      setState((prevState) => ({ ...prevState, isLoading: true }));
      try {
        if (state.clientMode === "add") {
          const payload = {
            name: clientData.name,
            email: clientData.email,
            typeId: parseInt(clientData.typeId, 10),
            projectId: parseInt(projectId),
          };
          const response = await dispatch(
            createClient({ clientData: payload, token })
          ).unwrap();

          if (response) {
            setState((prevState) => ({
              ...prevState,
              clientData: [...prevState.clientData, response],
              updateClientList: prevState.updateClientList + 1,
            }));
            handleCloseModal();
          } else {
            console.error("Error creating client:", response.error);
          }
        } else if (state.clientMode === "edit" && state.selectedClientRow) {
          const payload = {
            name: clientData.name,
            email: clientData.email,
            typeId: parseInt(clientData.typeId, 10),
          };
          const response = await dispatch(
            updateClient({
              clientId: state.selectedClientRow.id,
              projectId: parseInt(projectId),
              clientData: payload,
              token,
            })
          ).unwrap();

          if (response) {
            setState((prevState) => ({
              ...prevState,
              clientData: prevState.clientData.map((client) =>
                client.id === state.selectedClientRow.id ? response : client
              ),
              updateClientList: prevState.updateClientList + 1,
            }));
            handleCloseModal();
          } else {
            console.error("Error updating client:", response.error);
          }
        }
      } catch (error) {
        console.error("Error creating/updating client:", error);
      } finally {
        setState((prevState) => ({ ...prevState, isLoading: false }));
      }
    },
    [
      dispatch,
      handleCloseModal,
      projectId,
      state.clientMode,
      state.selectedClientRow,
      token,
    ]
  );

  const columnsToDisplay = useMemo(
    () => [
      { colName: "name", label: t("Name"), showSorting: true },
      { colName: "typeName", label: t("Type"), showSorting: true },
      { colName: "email", label: t("Email"), showSorting: true },
      { colName: "Action", label: t("Action"), showSorting: false },
    ],
    [t]
  );

  const generateRowKey = (row) => row.id;

  const renderClientsTableCell = useCallback(
    (colName, value, row, index) => {
      if (colName === "Action") {
        return (
          <TableCell
            key="actions"
            sx={{ textAlign: "right", width: "150px" }}
          >
            <Box display="flex" justifyContent="flex-end">
              <IconButton
                disabled={state.isLoading}
                onClick={() => handleEdit(row)}
              >
                <Edit />
              </IconButton>
              <IconButton
                disabled={state.isLoading}
                onClick={() => openDeleteModal(row)}
              >
                <Delete />
              </IconButton>
            </Box>
          </TableCell>
        );
      }
      if (colName === "typeName") {
        return (
          <TableCell key={colName}>
            {row.clientOrganisationType?.name}
          </TableCell>
        );
      }

      return <TableCell key={index}>{value}</TableCell>;
    },
    [handleEdit, openDeleteModal, state.isLoading]
  );

  const filteredClientsData = useMemo(() => {
    if (!state.clientData) return [];
    const searchQueryLower = state.searchClientQuery.trim().toLowerCase();
    if (searchQueryLower === "") {
      return state.clientData;
    }
    return state.clientData.filter((item) =>
      item.name?.toLowerCase().includes(searchQueryLower)
    );
  }, [state.clientData, state.searchClientQuery]);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState(false);

  // Whenever isError or errorMessage changes, reset showErrorModal
  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={localErrorMessage}
          handleButtonClick={
            isGenericError
              ? () => handleLogoutError(dispatch, navigate)
              : handleCancelErrorModal
          }
          deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
        />
      </div>
    );
  }

  return (
    <div className="all-client-container">
      {state.isLoading && (
        <MotifProgressLoader
          className="loader"
          show
          variant="default"
          data-testid="loading-spinner"
        />
      )}
      <Header />
      <div className="client-org-container">
        <Breadcrumbs
          items={[
            { label: t("AllProjects"), link: "/" },
            {
              label: projectName,
              link: `/project-home/${projectId}`,
              token: token,
            },
            {
              label: t("ClientOrganisation"),
              link: `/client-org/${projectId}`,
            },
          ]}
        />
        <div className="client-org-subheader">
          <Subheader
            isAdmin={isAdmin}
            page={languageData.ClientOrgPage}
            title={t("ClientOrganisation")}
            onChangeSearchInput={handleSearch}
            handleOpenModal={() => handleOpenModal("add")}
            disableButton={state.isLoading}
            CreateNewEN={t("CreateNew")}
            ClientOrgAccessTitleEN={t("ClientOrgPage")}
          />
          {state.isClientModalOpen && (
            <ClientModal
              onClose={handleCloseModal}
              setName={state.clientModalTitle}
              handleSourceValueClick={handleClientEditUpdateClick}
              modalMode={state.clientMode}
              nameLabel={t("Name")}
              typeLabel={t("Type")}
              EmailInput={t("EnterEmail")}
              InputName={t("EnterName")}
              SelectType={t("SelectType")}
              emailLabel={t("Email")}
              cancelButtonLabel={t("Cancel")}
              saveButtonLabel={t("DoneButton")}
              updateButtonLabel={t("Update")}
              types={state.types}
              isDisbaled={state.isLoading}
              selectedRow={state.selectedClientRow}
              pageName="Client"
            />
          )}
        </div>
        <ClientTable
          columns={columnsToDisplay}
          data={filteredClientsData}
          itemsPerPage={5}
          generateRowKey={generateRowKey}
          renderTableCell={(colName, value, row, index) => {
            const cellValue = colName
              .split(".")
              .reduce((acc, part) => acc?.[part], row);
            return renderClientsTableCell(colName, cellValue, row, index);
          }}
        />

        {state.isDeleteModalOpen && (
          <DeleteModal
            isOpen={state.isDeleteModalOpen}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                isDeleteModalOpen: false,
              }))
            }
            setName={t("DeleteLabel")}
            labelText={t("DeletConfirmationSources")}
            onCancel={() =>
              setState((prevState) => ({
                ...prevState,
                isDeleteModalOpen: false,
              }))
            }
            handleDeleteClick={handleDelete}
            cancelButtonLabel={t("Cancel")}
            deleteButtonLabel={t("DeleteLabel")}
          />
        )}
      </div>
    </div>
  );
};

export default ClientOrg;
