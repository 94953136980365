import ExcelJS from "exceljs";

export const handleDownloadIroReport = async (iroReportingData) => {
  if (!iroReportingData) return;

  try {
    const apiData = Array.isArray(iroReportingData)
      ? iroReportingData
      : [iroReportingData];

    const workbook = new ExcelJS.Workbook();
    const sheetNames = new Set();

    if (apiData.length === 0) {
      workbook.addWorksheet("Blank Sheet");
    } else {
      for (const [index, item] of apiData.entries()) {
        let baseSheetName = `${index + 1}.${
          item.iroDetails?.name || `IRO`
        }`.replace(/[\\/*?[\]:]/g, "");
        if (baseSheetName.length > 31) {
          baseSheetName = baseSheetName.substring(0, 31);
        }
        baseSheetName = baseSheetName.replace(/^'|'$/g, "");

        let sheetName = baseSheetName;
        let counter = 1;
        while (sheetNames.has(sheetName)) {
          const suffix = `_${counter}`;
          const maxLength = 31 - suffix.length;
          sheetName = `${baseSheetName.substring(0, maxLength)}${suffix}`;
          counter++;
        }
        sheetNames.add(sheetName);

        const iroSheet = workbook.addWorksheet(sheetName);

        const iroHeader = [
          ["IRO", item.iroDetails?.name || "N/A"],
          ["Score", item.maxScore || "N/A"],
          ["Material", item.isAssessmentMaterial ? "Yes" : "No"],
          [
            "Pillar/Area",
            `${item.iroDetails?.pillar || "N/A"}/${
              item.iroDetails?.area || "N/A"
            }`,
          ],
          ["Type", "Impact"],
          ["Timeframe", item.iroDetails?.timeframeType || "N/A"],
          ["Description", item.iroDetails?.description || "N/A"],
        ];

        iroHeader.forEach((row, rowIndex) => {
          iroSheet.addRow(row);
          iroSheet.getCell(`A${rowIndex + 1}`).font = { bold: true };
          iroSheet.getCell(`B${rowIndex + 1}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "ADD8E6" },
          };
        });

        iroSheet.columns.forEach((column) => {
          let maxLength = 0;
          column.eachCell({ includeEmpty: true }, (cell) => {
            const columnLength = cell.value ? cell.value.toString().length : 10;
            if (columnLength > maxLength) {
              maxLength = columnLength;
            }
          });
          column.width = maxLength + 2;
        });

        iroSheet.addRow([]);
        iroSheet.addRow(["Context"]).font = { bold: true, size: 12 };

        const contextHeader = [
          "Sustainability",
          "Valuechain",
          "Sources",
          "Aps",
          "Stakeholders",
          "Geography",
          "RACI Mapping",
        ];

        const contextHeaderRow = iroSheet.addRow(contextHeader);
        contextHeaderRow.eachCell((cell, colNumber) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: {
              argb: [
                "#7F7F91",
                "#4E4E5D",
                "#26A04B",
                "#229B9A",
                "#188CE5",
                "#9D7CD4",
                "#FF4136",
              ][colNumber - 1],
            },
          };
          cell.font = { bold: true, color: { argb: "FFFFFF" } };
        });

        iroSheet.addRow([
          item.understandingTheContextList?.sustainabilityMatters.join(", ") ||
            "N/A",
          item.understandingTheContextList?.valueChains.join(", ") || "N/A",
          item.understandingTheContextList?.sources.join(", ") || "N/A",
          item.understandingTheContextList?.actProServices.join(", ") || "N/A",
          item.understandingTheContextList?.stakeHolders.join(", ") || "N/A",
          item.understandingTheContextList?.geographicalLocations.join(", ") ||
            "N/A",
          ` ${item.iroDetails?.raci?.responsible || "N/A"}, ${
            item.iroDetails?.raci?.accountable || "N/A"
          },  ${item.iroDetails?.raci?.informed || "N/A"}`,
        ]);

        iroSheet.addRow([]);
        iroSheet.addRow(["Assessments"]).font = { bold: true, size: 12 };
        const assessmentsHeader = [
          "Material",
          "Score",
          "Value Chain",
          "Positive/Negative",
          "Actual/Potential",
          "Scale",
          "Scale Description",
          "Scope",
          "Scope Description",
          "Remediability",
          "Remediability Description",
          "Magnitude",
          "Magnitude Description",
          "Likelihood",
          "Likelihood Description",
        ];

        const assessmentsHeaderRow = iroSheet.addRow(assessmentsHeader);
        assessmentsHeaderRow.eachCell((cell) => {
          cell.fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "0070C0" },
          };
          cell.font = { bold: true, color: { argb: "FFFFFF" } };
        });

        item.assessments.forEach((assessment) => {
          iroSheet.addRow([
            assessment?.isMaterial ? "Yes" : "No",
            assessment?.score || "N/A",
            assessment?.valueChains?.join(", ") || "N/A",
            assessment?.isPositive ? "Positive" : "Negative",
            assessment?.isActual ? "Actual" : "Potential",
            assessment?.scaleLabel || "N/A",
            assessment?.ratingScaleDescription || "N/A",
            assessment?.scopeLabel || "N/A",
            assessment?.ratingScopeDescription || "N/A",
            assessment?.remediabilityLabel || "N/A",
            assessment?.ratingRemediabilityDescription || "N/A",
            assessment?.magnitudeLabel || "N/A",
            assessment?.ratingRatingMagnitudeIdDescription || "N/A",
            assessment?.likelihood || "N/A",
            assessment?.ratingLikelihoodDescription || "N/A",
          ]);
        });

        iroSheet.columns.forEach((column) => {
          let maxLength = 0;
          column.eachCell({ includeEmpty: true }, (cell) => {
            const columnLength = cell.value ? cell.value.toString().length : 10;
            if (columnLength > maxLength) {
              maxLength = columnLength;
            }
          });
          column.width = maxLength + 2;
        });
      }
    }

    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "IRO_Report.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error generating Excel file:", error);
  }
};
