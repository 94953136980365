import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifMessage,
  MotifRadioButton,
  MotifToggleSwitch,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./SustainabilityMatters.scss";
import { useTranslation } from "react-i18next";

const SustainabilityMattersModal = ({
  onClose,
  modalMode,
  modalTitle,
  topicNameLabel,
  parentLabel,
  esrsAr16Label,
  esgLabel,
  enterTopicNameLabel,
  selectParentLabel,
  selectEsrsAr16Label,
  isHumanRightsRelatedLabel,
  DescriptionLabel,
  saveButtonLabel,
  updateButtonLabel,
  cancelButtonLabel,
  esrsar16Options,
  parentOptions,
  handleDoneClick,
  filteredUpdateData,
  onDeleteClick,
  DeleteLabel,
  updateItemID,
  isDisabled,
  ExplanationLabel,
}) => {
  const isEditMode = modalMode === "edit";
  const [selectedTab, setSelectedTab] = useState("Definition");
  const [esrsAr16Error, setEsrsAr16Error] = useState("");
  const [topicNameError, setTopicNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [definitionError, setDefinitionError] = useState("");
  const [isFormValid, setIsFormValid] = useState(false);

  // Maximum character limit
  const MAX_CHAR_LIMIT = 3000;

  const [formData, setFormData] = useState({
    topicName: "",
    parent: "",
    esrsAr16: "",
    esg: "E",
    humanRightsToggle: false,
    definition: "",
    description: "",
  });
  const {
    topicName,
    parent,
    esrsAr16,
    esg,
    description,
    definition,
    humanRightsToggle,
  } = formData;
  const { t } = useTranslation();

  useEffect(() => {
    if (filteredUpdateData && isEditMode) {
      setFormData({
        topicName: filteredUpdateData.label || "",
        parent:
          filteredUpdateData?.parentId !== null
            ? filteredUpdateData?.parentId.toString()
            : "",
        esrsAr16: filteredUpdateData?.esrsaR16.id.toString() || 0,
        description: filteredUpdateData?.description,
        esg: filteredUpdateData.esg,
        humanRightsToggle: filteredUpdateData.isHumanRightsRelated,
        definition: filteredUpdateData?.definition,
      });
    }
  }, [filteredUpdateData, isEditMode]);

  useEffect(() => {
    validateFields();
  }, [formData]);

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const changeToggle = () => {
    setFormData((prevData) => ({
      ...prevData,
      humanRightsToggle: !prevData.humanRightsToggle,
    }));
  };
  const validateFields = (isSubmitting = false) => {
    let isValid = true;

    if (!esrsAr16) {
      if (isSubmitting) setEsrsAr16Error("EsrsAr16 is required");
      isValid = false;
    } else {
      setEsrsAr16Error("");
    }

    if (!topicName) {
      if (isSubmitting) setTopicNameError("Topic name is required");
      isValid = false;
    } else {
      setTopicNameError("");
    }

    if (!description) {
      if (isSubmitting) setDescriptionError("Description is required");
      isValid = false;
    } else if (description.length > MAX_CHAR_LIMIT) {
      if (isSubmitting)
        setDescriptionError(
          `Explanation should not exceed ${MAX_CHAR_LIMIT} characters`
        );
      isValid = false;
    } else {
      setDescriptionError("");
    }

    if (!definition) {
      if (isSubmitting) setDefinitionError("Explanation is required");
      isValid = false;
    } else if (definition.length > MAX_CHAR_LIMIT) {
      if (isSubmitting)
        setDefinitionError(
          `Explanation should not exceed ${MAX_CHAR_LIMIT} characters`
        );
      isValid = false;
    } else {
      setDefinitionError("");
    }

    setIsFormValid(isValid);
    return isValid;
  };

  const handleSubmit = () => {
    if (validateFields(true)) {
      const data = {
        sustainabilityMatterId: 1100000,
        parentId: parseInt(parent, 10) || null,
        esrsaR16Id: parseInt(esrsAr16, 10),
        name: topicName,
        esg,
        description: description || "",
        definition: definition || "",
        aggregatedGroupName: "string",
        isRelevant: true,
        explanationOfRelevance: "string",
        sortOrder: 0,
        isHumanRightsRelated: humanRightsToggle,
      };

      handleDoneClick(data);
      onClose();
    }
  };

  const renderTabContent = () => {
    switch (selectedTab) {
      case "Company context":
        return (
          <div className="form-row sustain-description-label">
            <MotifMessage id="select-label" data-testid="description-label">
              {DescriptionLabel}
            </MotifMessage>
            <TextField
              placeholder=""
              multiline
              fullWidth
              rows={4}
              value={description}
              onChange={(e) => handleInputChange("description", e.target.value)}
            />
            {descriptionError && (
              <MotifErrorMessage>{descriptionError}</MotifErrorMessage>
            )}
          </div>
        );
      case "Definition":
        return (
          <>
            <div className="form-row">
              <div className="form-group">
                <MotifMessage data-testid="topic-name">
                  {topicNameLabel}*
                </MotifMessage>
                <MotifFormField>
                  <MotifLabel
                    id="select-dma-name-input-label"
                    position="in"
                    htmlFor="name-input"
                  >
                    {enterTopicNameLabel}
                  </MotifLabel>
                  <MotifInput
                    className="session-modal-input session-name-input"
                    labelPosition="in"
                    aria-describedby="session-name"
                    hideClearButton={true}
                    value={topicName}
                    onChange={(e) =>
                      handleInputChange("topicName", e.target.value)
                    }
                  />
                  {topicNameError && (
                    <MotifErrorMessage>{topicNameError}</MotifErrorMessage>
                  )}
                </MotifFormField>
              </div>
              <div className="form-group">
                <MotifMessage id="form-title">{parentLabel}</MotifMessage>
                <MotifFormField>
                  <MotifLabel id="select-dma-select-label" position="in">
                    {selectParentLabel}
                  </MotifLabel>
                  <MotifSelect
                    className="session-modal-input"
                    ariaLabelledBy="select-dma-select-label"
                    labelPosition="in"
                    visibleOptions={3}
                    value={parent}
                    onChange={(e) => handleInputChange("parent", e)}
                  >
                    {parentOptions
                      .filter((option) => option.value !== updateItemID)
                      .map((type) => (
                        <MotifOption
                          key={type.value}
                          value={type.value.toString()}
                        >
                          {type.label}
                        </MotifOption>
                      ))}
                  </MotifSelect>
                </MotifFormField>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group full-width">
                <MotifMessage id="form-title" data-testid="esrs-ar16">
                  {esrsAr16Label}*
                </MotifMessage>
                <MotifFormField>
                  <MotifLabel id="select-dma-select-label" position="in">
                    {selectEsrsAr16Label}
                  </MotifLabel>
                  <MotifSelect
                    className="session-modal-input"
                    ariaLabelledBy="select-dma-select-label"
                    labelPosition="in"
                    visibleOptions={3}
                    value={esrsAr16}
                    onChange={(e) => handleInputChange("esrsAr16", e)}
                    style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                  >
                    {esrsar16Options.map((type) => (
                      <MotifOption
                        key={type.value}
                        value={type.value.toString()}
                      >
                        {type.label}
                      </MotifOption>
                    ))}
                  </MotifSelect>
                  {esrsAr16Error && (
                    <MotifErrorMessage>{esrsAr16Error}</MotifErrorMessage>
                  )}
                </MotifFormField>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group half-width">
                <MotifMessage data-testid="esg">{esgLabel}*</MotifMessage>
                <div className="radio-group">
                  <MotifFormField>
                    <MotifRadioButton
                      name="esg"
                      onChange={(e) => handleInputChange("esg", e.target.value)}
                      value="E"
                      checked={esg === "E"}
                    >
                      E
                    </MotifRadioButton>
                    <MotifRadioButton
                      name="esg"
                      onChange={(e) => handleInputChange("esg", e.target.value)}
                      value="S"
                      className="margin-left-radio"
                      checked={esg === "S"}
                    >
                      S
                    </MotifRadioButton>
                    <MotifRadioButton
                      name="esg"
                      onChange={(e) => handleInputChange("esg", e.target.value)}
                      value="G"
                      className="margin-left-radio"
                      checked={esg === "G"}
                    >
                      G
                    </MotifRadioButton>
                  </MotifFormField>
                </div>
              </div>
              <div className="form-group half-width">
                <MotifMessage data-testid="human-rights">
                  {isHumanRightsRelatedLabel}*
                </MotifMessage>
                <MotifFormField className="human-rights-toggle">
                  <MotifToggleSwitch
                    onChange={changeToggle}
                    id="exampleToggle"
                    hideLabels={true}
                    checked={humanRightsToggle}
                  />
                </MotifFormField>
              </div>
            </div>
            <div className="form-row">
              <div className="form-group full-width">
                <MotifMessage data-testid="description-label">
                  {ExplanationLabel}*
                </MotifMessage>
                <TextField
                  placeholder=""
                  multiline
                  fullWidth
                  rows={4}
                  value={definition}
                  onChange={(e) =>
                    handleInputChange("definition", e.target.value)
                  }
                />
                {definitionError && (
                  <MotifErrorMessage>{definitionError}</MotifErrorMessage>
                )}
              </div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="modal-overlay">
      <div className="sustain-modal modal">
        <div className="modal-header">
          <h2>{modalTitle}</h2>
        </div>
        <div className="modal-body">
          <div className="modal-sidebar">
            <ul>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Definition" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Definition")}
                >
                  {t("Definition")}
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className={`sidebar-item ${
                    selectedTab === "Company context" ? "active" : ""
                  }`}
                  onClick={() => setSelectedTab("Company context")}
                >
                  {t("Company context")}
                </button>
              </li>
            </ul>
          </div>
          <div className="modal-content">{renderTabContent()}</div>
        </div>
        <div className="dma-modal-footer">
          <div className="left-buttons">
            {isEditMode && (
              <MotifButton
                className="modal-delete-button"
                onClick={onDeleteClick}
              >
                {DeleteLabel}
              </MotifButton>
            )}
          </div>
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="modal-cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton
              onClick={handleSubmit}
              className="modal-done-button"
              disabled={isDisabled}
            >
              {isEditMode ? updateButtonLabel : saveButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

SustainabilityMattersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  topicNameLabel: PropTypes.string.isRequired,
  parentLabel: PropTypes.string.isRequired,
  esrsAr16Label: PropTypes.string.isRequired,
  esgLabel: PropTypes.string.isRequired,
  enterTopicNameLabel: PropTypes.string.isRequired,
  selectParentLabel: PropTypes.string.isRequired,
  selectEsrsAr16Label: PropTypes.string.isRequired,
  isHumanRightsRelatedLabel: PropTypes.string.isRequired,
  DescriptionLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  DeleteLabel: PropTypes.string.isRequired,
  esrsar16Options: PropTypes.array,
  parentOptions: PropTypes.array,
  handleDoneClick: PropTypes.func.isRequired,
  filteredUpdateData: PropTypes.object,
  updateItemID: PropTypes.number,
  isDisabled: PropTypes.bool,
  ExplanationLabel: PropTypes.string.isRequired,
};

export default SustainabilityMattersModal;
