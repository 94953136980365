import { PublicClientApplication } from "@azure/msal-browser";

export const authConfig = {
  clientId: process.env.REACT_APP_AZURE_CLIENT_ID,
  authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_APPLICATION_ID}`,
  postLogoutRedirectUri: process.env.REACT_APP_BASE_DOMAIN_URL,
  navigateToLoginRequestUrl: true,
  applicationId: process.env.REACT_APP_AZURE_APPLICATION_ID,
};

const cache = {
  cacheLocation: "sessionStorage",
  storeAuthStateInCookie: true,
};
const getMsalConfig = () => {
  const msalConfig = {
    auth: authConfig,
    cache: cache,
  };

  return msalConfig;
};

const msalConfig = getMsalConfig();
export const msalInstance = new PublicClientApplication(msalConfig);

