import React, { useState } from "react";
import Stepper from "../../components/stepper/Stepper";
import Header from "../../components/headers/Header";
import {
  Beat,
  BeatLite,
  Stake,
  StakeLite,
  DgaAssessmentIcon,
  DgaAssessmentPeople,
} from "../../constants/icons/Icons";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router-dom";
import AssesmentDga from "./Assessment/AssessmentDga";
import DgaUnderstandTheContext from "./UnderstandTheContext/UnderstandTheContext";
import "./DataPointGapAssessment.scss";

const DataPointGapAssessment = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeTab, setActiveTab] = useState("sources");
  const [assessmentDgaId, setAssessmentDgaId] = useState(null);

  const { moduleId } = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const projectId = location.state?.projectId;
  const token = location.state?.token;
  const projectName = location.state?.projectName;
  const sessionData = location.state?.sessionData;

  const handleStepChange = (index) => {
    setActiveIndex(index);
  };

  const switchToUnderstandTheContext = (tab) => {
    setActiveTab(tab);
    handleStepChange(1);
  };

  const getIcon = (index, activeIcon, inactiveIcon) => {
    return activeIndex === index ? activeIcon : inactiveIcon;
  };

  const project = sessionData?.find((project) =>
    project.modules.some((module) => module.id === parseInt(moduleId))
  );

  const stepItems = [
    { label: t("All projects"), link: "/" },
    { label: projectName, link: `/project-home/${projectId}`, token },
    { label: project?.name, link: `/project-home/${projectId}`, token },
    { label: t("DGA"), link: "#" },
  ];

  const navigateToAssessment = () => {
    handleStepChange(1);
  };

  return (
    <div className="dga">
      <Header />
      <Stepper
        activeIndex={activeIndex}
        onChange={handleStepChange}
        items={stepItems}
      >
        <div
          icon={getIcon(0, <Stake />, <StakeLite />)}
          text={t("UnderstandTheContext")}
        >
          <DgaUnderstandTheContext
            onTabChange={switchToUnderstandTheContext}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setAssessmentDgaId={setAssessmentDgaId}
            navigateToAssessment={navigateToAssessment}
          />
        </div>
        <div
          icon={getIcon(1, <DgaAssessmentPeople />, <DgaAssessmentIcon />)}
          text={t("Assessment")}
        >
          <AssesmentDga assessmentDgaId={assessmentDgaId} />
        </div>
        <div icon={getIcon(2, <Beat />, <BeatLite />)} text={t("Lorem Ipsum")}>
          {/* <AssesmentDga onTabChange={switchToUnderstandTheContext} /> */}
        </div>
      </Stepper>
    </div>
  );
};

export default DataPointGapAssessment;
