import React, { useState, useEffect } from "react";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { TableCell, IconButton, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import ClientTable from "../../../../components/clientTable/ClientTable";
import ScoreModal from "../../../../components/modals/dmaModals/ScoreModal";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import { Edit } from "../../../../constants/icons/Icons";
import {
  fetchScores,
  createScores,
  updateScores,
  deleteScores,
  clearError,
} from "../../../../features/slices/Scores";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import "./Score.scss";
import ErrorModal from "../../../../components/modals/error/errorModal";
import { handleError as handleLogoutError } from "../../../../utils/handleError";

const Score = () => {
  const dispatch = useDispatch();
  const { isError, errorMessage, isGenericError } = useSelector(
    (state) => state.scores
  );
  const { moduleId: moduleIdString } = useParams();
  const moduleId = parseInt(moduleIdString);
  const location = useLocation();
  const token = location.state?.token;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [state, setState] = useState({
    isLoading: false,
    scoreData: [],
    isScoreModalOpen: false,
    modalScoreMode: "add",
    modalScoreTitle: "",
    selectedScoreRow: null,
    isDeleteModalOpen: false,
    scoreToDelete: null,
  });

  const {
    isLoading,
    scoreData,
    isScoreModalOpen,
    modalScoreMode,
    modalScoreTitle,
    selectedScoreRow,
    isDeleteModalOpen,
    scoreToDelete,
  } = state;

  const setPartialState = (partialState) =>
    setState((prevState) => ({ ...prevState, ...partialState }));

  const fetchData = async () => {
    setPartialState({ isLoading: true });
    try {
      const response = await dispatch(
        fetchScores({ moduleId, token })
      ).unwrap();
      setPartialState({ scoreData: transformScoreData(response.data) });
    } catch (error) {
      console.error("Error fetching scores:", error);
    } finally {
      setPartialState({ isLoading: false });
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch, moduleId, token]);

  const transformScoreData = (data) =>
    data.map((item) => ({
      id: item.id,
      rating: item.rating,
      scopeLabel: item.scopeLabel,
      scaleLabel: item.scaleLabel,
      remediabilityLabel: item.remediabilityLabel,
      magnitudeLabel: item.magnitudeLabel,
      scopeDefinitionPositive: item.scopeDefinitionPositive,
      scopeDefinitionNegative: item.scopeDefinitionNegative,
      scaleDefinitionPositive: item.scaleDefinitionPositive,
      scaleDefinitionNegative: item.scaleDefinitionNegative,
      remediabilityDefinitionPositive: item.remediabilityDefinitionPositive,
      remediabilityDefinitionNegative: item.remediabilityDefinitionNegative,
      magnitudeDefinitionPositive: item.magnitudeDefinitionPositive,
      magnitudeDefinitionNegative: item.magnitudeDefinitionNegative,
    }));

  const openScoreModal = (mode, row = null) => {
    setPartialState({
      modalScoreMode: mode,
      modalScoreTitle: mode === "add" ? t("New score") : t("Edit Score"),
      selectedScoreRow: row,
      isScoreModalOpen: true,
    });
    setPartialState({ scoreToDelete: row });
  };

  const openDeleteModal = () => {
    setPartialState({ isDeleteModalOpen: true });
  };

  const handleSaveOrUpdateScore = async (formData) => {
    setPartialState({ isLoading: true });
    try {
      const formattedData = formatScoreData(formData);
      if (modalScoreMode === "add") {
        await dispatch(
          createScores({ moduleId, token, scoresData: formattedData })
        ).unwrap();
      } else if (modalScoreMode === "edit" && selectedScoreRow) {
        await dispatch(
          updateScores({
            moduleId,
            token,
            scoresId: selectedScoreRow.id,
            scoresData: formattedData,
          })
        ).unwrap();
      }
      await fetchData();
    } catch (error) {
      console.error("Error saving/updating score:", error);
    } finally {
      handleCloseModal();
    }
  };

  const formatScoreData = (formData) => ({
    rating: formData.rating,
    scopeLabel: formData.scopeLabel,
    scopeDefinitionPositive: formData.scopeDefinitionPositive,
    scopeDefinitionNegative: formData.scopeDefinitionNegative,
    scaleLabel: formData.scaleLabel,
    scaleDefinitionPositive: formData.scaleDefinitionPositive,
    scaleDefinitionNegative: formData.scaleDefinitionNegative,
    remediabilityLabel: formData.remediabilityLabel,
    remediabilityDefinitionPositive: formData.remediabilityDefinitionPositive,
    remediabilityDefinitionNegative: formData.remediabilityDefinitionNegative,
    magnitudeLabel: formData.magnitudeLabel,
    magnitudeDefinitionPositive: formData.magnitudeDefinitionPositive,
    magnitudeDefinitionNegative: formData.magnitudeDefinitionNegative,
  });

  const handleDelete = async () => {
    if (!scoreToDelete) return;

    const remainingScores = scoreData.filter(
      (score) => score.id !== scoreToDelete.id
    );

    if (remainingScores.length < 3) {
      alert("Minimum 3 Rows should be present");
      setPartialState({ isDeleteModalOpen: false });
      return;
    }
    setPartialState({ isLoading: true });
    try {
      await dispatch(
        deleteScores({ scoresId: scoreToDelete.id, moduleId, token })
      ).unwrap();
      await fetchData();
    } catch (error) {
      console.error("Error deleting score:", error);
    } finally {
      setPartialState({ isDeleteModalOpen: false, isScoreModalOpen: false });
    }
  };

  const handleCloseModal = () => setPartialState({ isScoreModalOpen: false });

  const columnsScore = [
    { colName: "rating", label: t("Rating"), showSorting: true },
    { colName: "scaleLabel", label: t("Scale"), showSorting: false },
    { colName: "scopeLabel", label: t("Scope"), showSorting: false },
    {
      colName: "remediabilityLabel",
      label: t("Remediability"),
      showSorting: false,
    },
    {
      colName: "magnitudeLabel",
      label: t("Financial Magnitude"),
      showSorting: false,
    },
    { colName: "Action", label: t("Action"), showSorting: false },
  ];

  const renderScoreTableCell = (colName, value, row, index) => {
    if (colName === "Action") {
      return (
        <TableCell key="actions" sx={{ textAlign: "right", width: "100px" }}>
          <Box display="flex" justifyContent="flex-end">
            <IconButton onClick={() => openScoreModal("edit", row)}>
              <Edit />
            </IconButton>
          </Box>
        </TableCell>
      );
    }
    return <TableCell key={index}>{value}</TableCell>;
  };

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState(false);

  // Whenever isError or errorMessage changes, reset showErrorModal
  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={localErrorMessage}
          handleButtonClick={
            isGenericError
              ? () => handleLogoutError(dispatch, navigate)
              : handleCancelErrorModal
          }
          deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
        />
      </div>
    );
  }

  return (
    <div>
      {isLoading && (
        <MotifProgressLoader className="loader" show variant="default" />
      )}

      <ContextSelection
        addButtonText={t("Add New")}
        onClickNewProject={() => openScoreModal("add")}
        pageName={t("Scores")}
      />

      <div className="gl-card-table-switch">
        <ClientTable
          columns={columnsScore}
          data={scoreData}
          itemsPerPage={5}
          renderTableCell={renderScoreTableCell}
          generateRowKey={(row) => row.id}
        />
      </div>

      {isScoreModalOpen && (
        <ScoreModal
          onClose={handleCloseModal}
          modalTitle={modalScoreTitle}
          modalMode={modalScoreMode}
          initialData={selectedScoreRow}
          onSubmit={handleSaveOrUpdateScore}
          disable={isLoading}
          ratingLabel={t("RatingLabel")}
          topicNameLabel={t("RatingLevel")}
          InputRating={t("RatingGuide")}
          enterTopicNameLabel={t("New")}
          DefinitionPositiveLabel={t("DefinitionPositive")}
          DefinitionNegativeLabel={t("DefinitionNegative")}
          DescriptionLabel={t("Definition")}
          saveButtonLabel={t("AddScore")}
          updateButtonLabel={t("Update")}
          cancelButtonLabel={t("Cancel")}
          onDeleteClick={openDeleteModal}
          DeleteLabel={t("DeleteLabel")}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteModal
          isOpen={isDeleteModalOpen}
          onClose={() => setPartialState({ isDeleteModalOpen: false })}
          setName={t("DeleteLabel")}
          labelText={t("DeletConfirmationSources")}
          onCancel={() => setPartialState({ isDeleteModalOpen: false })}
          handleDeleteClick={handleDelete}
          cancelButtonLabel={t("Cancel")}
          deleteButtonLabel={t("DeleteLabel")}
        />
      )}
    </div>
  );
};

export default Score;