import ExcelJS from "exceljs";

const downloadShortlistExcel = async (data) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  worksheet.columns = [
    { header: "ESRS-AR16", key: "esrs", width: 30 },
    { header: "Topic Name", key: "dr", width: 30 },
    { header: "Parent", key: "relatedAR", width: 30 },
    { header: "ESG", key: "dataType", width: 30 },
    { header: "Human rights related", key: "voluntary", width: 30 },
    { header: "Explanation", key: "question", width: 50 },
    { header: "Description", key: "desc", width: 50 },
  ];

  data.forEach((item) => {
    worksheet.addRow({
      esrs: item?.esrsAr16,
      dr: item?.topicName,
      relatedAR: item?.parentName,
      dataType: item?.esg,
      voluntary: item?.isHumanRightsRelated,
      question: item?.definition,
      desc: item?.description,
    });
  });

  worksheet.getRow(1).eachCell((cell) => {
    cell.font = { bold: true };
    cell.alignment = { horizontal: "center" };
  });

  worksheet.eachRow((row, rowNumber) => {
    if (rowNumber > 1) {
      row.getCell("dataType").alignment = { horizontal: "center" };
    }
  });

  worksheet.columns.forEach((column) => {
    let maxLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      const columnLength = cell.value ? cell.value.toString().length : 10;
      if (columnLength > maxLength) {
        maxLength = columnLength;
      }
    });
    column.width = maxLength + 2;
  });

  try {
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "ESG_Short_List_Data.xlsx";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.error("Error generating Excel file:", error);
  }
};

export default downloadShortlistExcel;
