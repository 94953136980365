import React, { useState, useCallback, useEffect, useRef } from "react";
import {
  MotifFormField,
  MotifOption,
  MotifInput,
  MotifLabel,
  MotifMessage,
  MotifSelect,
} from "@ey-xd/motif-react";
import { FETCH_AD_USERS } from "../../services/Api";
import MultiPeoplePicker from "../peoplePicker/MultiPeoplePicker";
import { useMsal } from "@azure/msal-react";
import PropTypes from "prop-types";
import "./NewProjectForm.scss";
import { Redirect } from "../../constants/icons/Icons";

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : null;
};

const NewProjectForm = ({ formFields, formState, setFormState }) => {
  const { instance } = useMsal();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [errors, setErrors] = useState({});

  const formStateRef = useRef(formState);
  useEffect(() => {
    formStateRef.current = formState;
  }, [formState]);

  // Ensure selectedUsers is updated when formState.projectAdmin changes
  useEffect(() => {
    const projectAdmins = formState.projectAdmin?.map((admin) => ({
      id: admin.id,
      value: admin.displayName,
      label: admin.emailId,
    }));
    setSelectedUsers(projectAdmins || []);
  }, [formState.projectAdmin]);

  const validateInput = (id, value) => {
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/g;
    if (id === "gisId" && value.length > 9) {
      return false;
    }
    if (id === "opportunity" && value.length > 20) {
      return false;
    }
    return !specialCharPattern.test(value);
  };

  const getErrorMessage = (id) => {
    if (id === "gisId") {
      return "GIS ID cannot exceed 9 digits";
    } else if (id === "opportunity") {
      return "Opportunity should not exceed 20 characters";
    } else {
      return "Special characters are not allowed";
    }
  };

  const handleInputChange = useCallback(
    (id, value, fieldType) => {
      if (fieldType === "input") {
        if (validateInput(id, value)) {
          setFormState({ ...formStateRef.current, [id]: value });
          setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
        } else {
          setErrors((prevErrors) => ({
            ...prevErrors,
            [id]: getErrorMessage(id),
          }));
        }
      } else {
        // For non-input fields like select
        setFormState({ ...formStateRef.current, [id]: value });
        setErrors((prevErrors) => ({ ...prevErrors, [id]: "" }));
      }
    },
    [setFormState, formFields]
  );

  const searchUsers = useCallback(async (searchQuery, accessToken) => {
    try {
      const filterQuery = encodeURIComponent(
        `startswith(displayName,'${searchQuery}') or startswith(mail,'${searchQuery}') or startswith(givenName,'${searchQuery}') or startswith(surname,'${searchQuery}')`
      );
      const response = await fetch(`${FETCH_AD_USERS}?$filter=${filterQuery}`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`Error fetching users: ${response.statusText}`);
      }

      const data = await response.json();
      return data.value;
    } catch (error) {
      console.error("Error in searchUsers:", error);
      return [];
    }
  }, []);

  const getADUsers = useCallback(
    async (inputValue) => {
      const accounts = instance.getAllAccounts();
      if (accounts.length > 0) {
        try {
          const tokenResponse = await instance.acquireTokenSilent({
            scopes: ["User.ReadBasic.All"],
            account: accounts[0],
          });
          const accessToken = tokenResponse.accessToken;
          const users = await searchUsers(inputValue, accessToken);
          return users.map((user) => ({
            id: user.id,
            displayName: user.displayName,
            emailId: user.mail,
          }));
        } catch (error) {
          console.error("Error fetching AD users:", error);
          return [];
        }
      } else if (getCookie("accessToken")) {
        const users = await searchUsers(inputValue, getCookie("accessToken"));
        return users.map((user) => ({
          id: user.id,
          displayName: user.displayName,
          emailId: user.mail,
        }));
      }
      return [];
    },
    [instance, searchUsers]
  );

  const handleChange = useCallback(
    (selectedList) => {
      setSelectedUsers(selectedList);
      setFormState({
        ...formStateRef.current,
        projectAdmin: selectedList.map((user) => ({
          id: user.id,
          displayName: user.value,
          emailId: user.label,
        })),
      });
    },
    [setFormState]
  );

  const renderFormField = (field) => {
    switch (field.type) {
      case "input":
        return (
          <>
            <MotifInput
              className={`new-project-input ${field.id}`}
              value={formState[field.id] || ""}
              data-testId={field.id}
              onChange={(e) =>
                handleInputChange(field.id, e.target.value, field.type)
              }
              onKeyPress={(e) => {
                const specialCharPattern = /[^a-zA-Z0-9 ]/;
                if (specialCharPattern.test(e.key)) {
                  e.preventDefault();
                }
              }}
              hideClearButton={true}
              labelPosition="in"
            />
            {errors[field.id] && (
              <MotifMessage className="error-message">
                {errors[field.id]}
              </MotifMessage>
            )}
          </>
        );
      case "select":
        return (
          <MotifSelect
            className="new-project-input"
            value={formState[field.id] || ""}
            filter={field.filter}
            multiple={field.multiple}
            onChange={(value) => handleInputChange(field.id, value, field.type)}
            hideClearButton={true}
            visibleOptions={3}
            labelPosition="in"
          >
            {field.options.map((option) => (
              <MotifOption key={option.id} value={option.id?.toString()}>
                {option.name}
              </MotifOption>
            ))}
          </MotifSelect>
        );
      case "multiSelect":
        return (
          <MultiPeoplePicker
            getADUsers={getADUsers}
            onChange={(selectedList) => handleChange(selectedList)}
            activeDirectoryData={selectedUsers}
            options={[selectedUsers]}
            disabled={false}
            isMandatory={true}
            label="Users"
            value={selectedUsers}
            isErrorVisible={false}
            errorMessage=""
            isErrorVisible2={false}
            errorMessage2=""
            selectionLimit={5}
            pageName={"New Project"}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="form-row">
      {formFields.map((field) => (
        <div key={field.id} className={`form-group ${field.class}`}>
          <div className="field-label">
            <MotifMessage id={`${field.id}-message`}>
              {field.label}
            </MotifMessage>
            {field.button && (
              <div
                className="icon-tooltip gis-icon"
                onClick={() => window.open(field.buttonUrl, "_blank")}
                data-tooltip="Open GIS"
              >
                <Redirect className="gis-button" />
              </div>
            )}
          </div>
          <MotifFormField>
            <MotifLabel htmlFor={`${field.id}-input`} position="in">
              {field.placeholder}
            </MotifLabel>
            {renderFormField(field)}
          </MotifFormField>
        </div>
      ))}
    </div>
  );
};

NewProjectForm.propTypes = {
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      type: PropTypes.oneOf(["input", "select", "multiSelect"]).isRequired,
      label: PropTypes.string.isRequired,
      placeholder: PropTypes.string.isRequired,
      class: PropTypes.string,
      filter: PropTypes.bool,
      multiple: PropTypes.bool,
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string.isRequired,
          label: PropTypes.string.isRequired,
        })
      ),
    })
  ).isRequired,
  formState: PropTypes.object.isRequired,
  setFormState: PropTypes.func.isRequired,
};

export default NewProjectForm;