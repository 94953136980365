import React from "react";
import {
  MotifLabel,
  MotifButton,
  MotifFormField,
  MotifSelect,
  MotifOption,
} from "@ey-xd/motif-react";
import {
  LeftArrowForPrevious,
  NavLeft,
  RightArrowForNext,
  DgaEdit,
  Edit,
  DgaQuestion
} from "../../constants/icons/Icons";
import "./IROAssessmentSubheader.scss";
import { Box, Typography, Card, CardContent, IconButton } from "@mui/material";
import PropTypes from "prop-types";

const IROAssessmentSubheader = ({
  header,
  subHeader,
  impact,
  previousButtonLabel,
  nextButtonLabel,
  onBackClick,
  handleNextClick,
  onEditClick,
  handlePreviousClick,
  SelectDisclosure,
  EsrsArray,
  GeneralDisclosureArray,
  SelectESRS,
  pagename,
  esrs,
  processText,
  generalDisclosure,
  setEsrs,
  setGeneralDisclosure,
  previousButtonDisabled,
}) => {
  return pagename === "IRO" ? (
    <div className="iro-assessment-subheader-container">
      <div className="iro-assessment-subheader-section">
        <div className="iro-assessment-subheader-section1">
          <div className="iro-left-arrow" onClick={onBackClick}>
            <NavLeft aria-label="nav-left-icon" />
          </div>

          <div className="iro-assessment-subheader-body">
            <div className="iro-assessment-name-section">
              <h2 className="motif-h6-default-regular"> {header}</h2>
              <p className="iro-impact iro-impact-label">{impact} </p>
              <IconButton onClick={onEditClick}>
                <Edit />
              </IconButton>
            </div>

            <span className="iro-dga-assessment-sub-name motif-body2-default-light">
              {subHeader}
            </span>
          </div>
        </div>
        <div className="iro-assessment-subheader-section2">
          <MotifButton
            className="modal-cancel-button next-previous-btn"
            onClick={handlePreviousClick}
            disabled={previousButtonDisabled}
          >
            <LeftArrowForPrevious aria-label="left-arrow-icon" /> 
            {previousButtonLabel}
          </MotifButton>
          <MotifButton
            className="modal-cancel-button next-previous-btn"
            onClick={handleNextClick}
          >
            {nextButtonLabel}
            <RightArrowForNext aria-label="right-arrow-icon" />
          </MotifButton>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="iro-dga-assessment-subheader-container">
        <div className="iro-dga-assessment-subheader-section">
          <div className="iro-dga-assessment-subheader-section1">
            <div className="iro-dga-assessment-subheader-body">
              <div className="iro-dga-assessment-name-section">
                <h2 className="motif-h6-default-regular">{header}</h2>
                <span className="iro-dga-assessment-sub-name motif-body2-default-light">
                  {subHeader}
                </span>
              </div>
            </div>
          </div>
          <div className="iro-dga-assessment-subheader-section2">
            <MotifButton
              className="dga-button"
              onClick={handlePreviousClick}
              size="small"
            >
              {previousButtonLabel} &nbsp; <DgaEdit />
            </MotifButton>
            <div className="select-box">
              <MotifFormField>
                <MotifLabel id="select-esrs-label" position="in">
                  {SelectESRS}
                </MotifLabel>
                <MotifSelect
                  className="select-dga-input"
                  ariaLabelledBy="select-esrs-label"
                  placeholder="Select ESRS"
                  onChange={(e) => setEsrs(e)}
                  value={esrs}
                  labelPosition="in"
                  visibleOptions={3}
                >
                  {EsrsArray?.map((item, index) => (
                    <MotifOption key={index} value={item}>
                      {item}
                    </MotifOption>
                  ))}
                </MotifSelect>
              </MotifFormField>
            </div>
            <div className="select-box">
              <MotifFormField>
                <MotifLabel id="select-disclosure-label" position="in">
                  {SelectDisclosure}
                </MotifLabel>
                <MotifSelect
                  className="select-dga-input"
                  ariaLabelledBy="select-disclosure-label"
                  placeholder="Select Disclosure"
                  onChange={(e) => setGeneralDisclosure(e)}
                  value={generalDisclosure}
                  labelPosition="in"
                  visibleOptions={3}
                >
                  {GeneralDisclosureArray?.map((item, index) => (
                    <MotifOption key={index} value={item}>
                      {item}
                    </MotifOption>
                  ))}
                </MotifSelect>
              </MotifFormField>
            </div>
          </div>
        </div>
      </div>
      <div className="iro-dga-assessment-subheader-container">
        <Card
          variant="outlined"
          sx={{
            display: "flex",
            alignItems: "center",
            p: 1,
            border: "none",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              bgcolor: "#083153",
              padding: "23px",
              margin: "1% 0% 3% 0%",
              height: "100%",
            }}
          >
            <DgaQuestion />
          </Box>
          <CardContent sx={{ p: 0 }}>
            <Typography
              sx={{ background: "#F4F7FC", padding: "12px" }}
              variant="body1"
              color="textPrimary"
            >
              {processText}
            </Typography>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

IROAssessmentSubheader.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  impact: PropTypes.string,
  previousButtonLabel: PropTypes.string,
  nextButtonLabel: PropTypes.string,
  onBackClick: PropTypes.func,
  handlePreviousClick: PropTypes.func,
  handleNextClick: PropTypes.func,
  SelectDisclosure: PropTypes.string,
  EsrsArray: PropTypes.array,
  GeneralDisclosureArray: PropTypes.array,
  SelectESRS: PropTypes.string,
  pagename: PropTypes.string,
  esrs: PropTypes.string,
  processText: PropTypes.string,
  generalDisclosure: PropTypes.string,
  setEsrs: PropTypes.func,
  onEditClick: PropTypes.func,
  setGeneralDisclosure: PropTypes.func,
  previousButtonDisabled: PropTypes.bool,
};

export default IROAssessmentSubheader;