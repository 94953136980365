import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  GET_UTC_COUNT,
  GET_MODULE_COUNT,
  FETCH_IRO_REPORTING_DATA,
  GET_EXCEL_SHORTLIST_DATA,
} from "../../services/Api";

// Constants for action types
const FETCH_UTC_COUNT = "dmaOverview/fetchUnderstandingTheContextCount";
const FETCH_IRO_COUNT = "dmaOverview/fetchIROCount";

// Helper function for API calls
const fetchData = async (url, token, rejectWithValue) => {
  try {
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(`Error fetching data from ${url}:`, error);
    return rejectWithValue(
      error.response?.data?.message ||
        error.response?.data?.title ||
        error.message ||
        `Error fetching data from ${url}`
    );
  }
};

export const fetchUnderstandingTheContextCount = createAsyncThunk(
  FETCH_UTC_COUNT,
  async ({ moduleId, token }, { rejectWithValue }) => {
    return fetchData(`${GET_UTC_COUNT}/${moduleId}`, token, rejectWithValue);
  }
);

export const fetchIroReportingData = createAsyncThunk(
  FETCH_IRO_REPORTING_DATA,
  async ({ moduleId, token }, { rejectWithValue }) => {
    return fetchData(
      `${FETCH_IRO_REPORTING_DATA}/${moduleId}`,
      token,
      rejectWithValue
    );
  }
);

export const fetchShortlistExcelData = createAsyncThunk(
  GET_EXCEL_SHORTLIST_DATA,
  async ({ moduleId, token }, { rejectWithValue }) => {
    return fetchData(
      `${GET_EXCEL_SHORTLIST_DATA}/${moduleId}`,
      token,
      rejectWithValue
    );
  }
);

// Async thunk for fetching IRO Count
export const fetchIROCount = createAsyncThunk(
  FETCH_IRO_COUNT,
  async ({ moduleId, token }, { rejectWithValue }) => {
    return fetchData(`${GET_MODULE_COUNT}/${moduleId}`, token, rejectWithValue);
  }
);

const initialState = {
  isLoading: false,
  contextData: null,
  iroReportingData: [],
  iroData: null,
  isError: false,
  errorMessage: "",
};

const dmaOverviewSlice = createSlice({
  name: "dmaOverview",
  initialState,
  extraReducers: (builder) => {
    const handlePending = (state) => {
      state.isLoading = true;
      state.errorMessage = "";
    };

    const handleFulfilled = (state, action, key) => {
      state.isLoading = false;
      state[key] = action.payload;
    };

    const handleRejected = (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.errorMessage = action.payload;
    };

    builder
      .addCase(fetchUnderstandingTheContextCount.pending, handlePending)
      .addCase(fetchUnderstandingTheContextCount.fulfilled, (state, action) =>
        handleFulfilled(state, action, "contextData")
      )
      .addCase(fetchUnderstandingTheContextCount.rejected, handleRejected)
      .addCase(fetchIroReportingData.pending, handlePending)
      .addCase(fetchIroReportingData.fulfilled, (state, action) =>
        handleFulfilled(state, action, "iroReportingData")
      )
      .addCase(fetchIroReportingData.rejected, handleRejected)
      .addCase(fetchShortlistExcelData.pending, handlePending)
      .addCase(fetchShortlistExcelData.fulfilled, (state, action) =>
        handleFulfilled(state, action, "shortlistExcelData")
      )
      .addCase(fetchShortlistExcelData.rejected, handleRejected)

      .addCase(fetchIROCount.pending, handlePending)
      .addCase(fetchIROCount.fulfilled, (state, action) =>
        handleFulfilled(state, action, "iroData")
      )
      .addCase(fetchIROCount.rejected, handleRejected);
  },
});

export default dmaOverviewSlice.reducer;
