import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifSelect,
  MotifOption,
  MotifMessage,
  MotifErrorMessage,
} from "@ey-xd/motif-react";
import Slider from "@mui/material/Slider";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./DmaModal.scss";

const StakeHolder = ({
  onClose,
  setName,
  handleDoneClick,
  modalMode,
  NameLabel,
  TypeLabel,
  IELabel,
  InfluenceLabel,
  PowerLabel,
  DescriptionLabel,
  cancelButtonLabel,
  saveButtonLabel,
  updateButtonLabel,
  selectedRow,
  typeOptions,
  ieOptions,
  InputName,
  SelectType,
  SelectIE,
  onDeleteClick,
  DeleteLabel,
}) => {
  const isEditMode = modalMode === "edit";

  const [formData, setFormData] = useState({
    name: "",
    type: 0,
    ie: 0,
    influence: 0,
    power: 0,
    description: "",
  });

  const MAX_CHAR_LIMIT = 3000;

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (selectedRow && isEditMode) {
      setFormData({
        name: selectedRow.name || "",
        type: selectedRow.stakeHolderType.id.toString() || 0,
        ie: selectedRow.stakeHolderIntExtType.id.toString() || 0,
        influence: parseFloat(selectedRow.influence) || 0,
        power: parseFloat(selectedRow.power) || 0,
        description: selectedRow.description || "",
      });
    }
  }, [selectedRow, isEditMode]);

  const validateForm = () => {
    const newErrors = {};
    const { name, type, ie, influence, power, description } = formData;

    if (!name.trim()) newErrors.name = "Name is required";
    if (!type) newErrors.type = "Type is required";
    if (!ie) newErrors.ie = "I/E is required";
    if (influence < 0 || influence > 10)
      newErrors.influence = "Influence must be between 0 and 10";
    if (power < 0 || power > 10)
      newErrors.power = "Power must be between 0 and 10";

    if (description.length > MAX_CHAR_LIMIT)
      newErrors.description = `Description should not exceed ${MAX_CHAR_LIMIT} characters`;

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (field, value) => {
    setFormData((prevData) => ({ ...prevData, [field]: value }));
  };

  const handleSave = () => {
    if (validateForm()) {
      handleDoneClick({
        name: formData.name,
        typeId: parseInt(formData.type, 10),
        intExtTypeId: parseInt(formData.ie, 10),
        influence: formData.influence,
        power: formData.power,
        description: formData.description,
      });
    }
  };

  const renderError = (error) =>
    error && (
      <MotifErrorMessage className="error-text">{error}</MotifErrorMessage>
    );

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{setName}</h2>
        </div>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group full-width">
              <MotifMessage id="form-title"> {NameLabel}*</MotifMessage>
              <MotifFormField>
                <MotifLabel
                  id="select-dma-name-input-label"
                  position="in"
                  htmlFor="name-input"
                >
                  {InputName}
                </MotifLabel>
                <MotifInput
                  id="name-input"
                  className="session-modal-input session-name-input"
                  labelPosition="in"
                  aria-describedby="session-name"
                  hideClearButton={true}
                  value={formData.name}
                  onChange={(e) => handleInputChange("name", e.target.value)}
                  error={!!errors.name}
                />
                {renderError(errors.name)}
              </MotifFormField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <MotifMessage id="session-framework">{TypeLabel}</MotifMessage>
              <MotifFormField>
                <MotifLabel
                  id="select-dma-select-label"
                  position="in"
                  htmlFor="type-select"
                >
                  {SelectType}
                </MotifLabel>
                <MotifSelect
                  id="type-select"
                  className="session-modal-input"
                  ariaLabelledBy="select-dma-select-label"
                  labelPosition="in"
                  visibleOptions={3}
                  onChange={(e) => handleInputChange("type", e)}
                  value={formData.type}
                  error={!!errors.type}
                >
                  {typeOptions.map(({ value, label }) => (
                    <MotifOption key={value} value={value.toString()}>
                      {label}
                    </MotifOption>
                  ))}
                </MotifSelect>
                {renderError(errors.type)}
              </MotifFormField>
            </div>
            <div className="form-group">
              <MotifMessage id="session-framework">{IELabel}</MotifMessage>
              <MotifFormField>
                <MotifLabel
                  id="select-ie-label"
                  position="in"
                  htmlFor="ie-select"
                >
                  {SelectIE}
                </MotifLabel>
                <MotifSelect
                  id="ie-select"
                  className="session-modal-input"
                  ariaLabelledBy="select-ie-label"
                  labelPosition="in"
                  visibleOptions={3}
                  onChange={(e) => handleInputChange("ie", e)}
                  value={formData.ie}
                  error={!!errors.ie}
                >
                  {ieOptions.map(({ value, label }) => (
                    <MotifOption key={value} value={value.toString()}>
                      {label}
                    </MotifOption>
                  ))}
                </MotifSelect>
                {renderError(errors.ie)}
              </MotifFormField>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group">
              <MotifLabel htmlFor="influence-slider">
                {InfluenceLabel}
              </MotifLabel>
              <MotifFormField>
                <Slider
                  id="influence-slider"
                  value={formData.influence}
                  color="#E6E6E9"
                  min={0}
                  max={10}
                  onChange={(e) =>
                    handleInputChange("influence", parseFloat(e.target.value))
                  }
                  valueLabelDisplay="auto"
                  marks={[
                    { value: 0, label: "0" },
                    { value: 10, label: "10" },
                  ]}
                />
                {renderError(errors.influence)}
              </MotifFormField>
            </div>
            <div className="form-group">
              <MotifLabel htmlFor="power-slider">{PowerLabel}</MotifLabel>
              <MotifFormField>
                <Slider
                  id="power-slider"
                  value={formData.power}
                  color="#E6E6E9"
                  min={0}
                  max={10}
                  onChange={(e) =>
                    handleInputChange("power", parseFloat(e.target.value))
                  }
                  valueLabelDisplay="auto"
                  marks={[
                    { value: 0, label: "0" },
                    { value: 10, label: "10" },
                  ]}
                />
                {renderError(errors.power)}
              </MotifFormField>
            </div>
          </div>
          <MotifMessage htmlFor="description-textfield">
            {DescriptionLabel}
          </MotifMessage>
          <TextField
            id="description-textfield"
            multiline
            fullWidth
            rows={4}
            value={formData.description}
            onChange={(e) => handleInputChange("description", e.target.value)}
            error={!!errors.description}
          />
          {renderError(errors.description)}
        </div>
        <div className="dma-modal-footer">
          <div className="left-buttons">
            {isEditMode && (
              <MotifButton
                className="modal-delete-button"
                onClick={onDeleteClick}
              >
                {DeleteLabel}
              </MotifButton>
            )}
          </div>
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="modal-cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton onClick={handleSave} className="modal-done-button">
              {isEditMode ? updateButtonLabel : saveButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

StakeHolder.propTypes = {
  onClose: PropTypes.func.isRequired,
  setName: PropTypes.string.isRequired,
  handleDoneClick: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  NameLabel: PropTypes.string.isRequired,
  TypeLabel: PropTypes.string.isRequired,
  IELabel: PropTypes.string.isRequired,
  InfluenceLabel: PropTypes.string.isRequired,
  PowerLabel: PropTypes.string.isRequired,
  DescriptionLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  selectedRow: PropTypes.object,
  typeOptions: PropTypes.array.isRequired,
  ieOptions: PropTypes.array.isRequired,
  DeleteLabel: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  InputName: PropTypes.string.isRequired,
  SelectType: PropTypes.string.isRequired,
  SelectIE: PropTypes.string.isRequired,
};

export default StakeHolder;
