import React, { useState, useEffect } from "react";
import ThresholdCard from "../../../../components/thresholdcard/ThresholdCard";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchThresholdTypes,
  fetchThreshold,
  createUpdateThreshold,
  clearError,
} from "../../../../features/slices/DMAProcessSpecificationThreshold";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import language from "../../../../constants/languages/en/translations.json";
import ErrorModal from "../../../../components/modals/error/errorModal";
import "./Threshold.scss";
import { MotifButton, MotifProgressLoader } from "@ey-xd/motif-react";
import { handleError as handleLogoutError } from "../../../../utils/handleError";

const Thresholds = () => {
  const [thresholds, setThresholds] = useState({ Impact: 0, Financial: 0 });
  const [initialThresholds, setInitialThresholds] = useState({
    Impact: 0,
    Financial: 0,
  });
  const [thresholdTypeIds, setThresholdTypeIds] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { moduleId } = useParams();
  const dispatch = useDispatch();
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");

  const {
    data: responseData,
    isLoading,
    isError,
    errorMessage,
    isGenericError,
  } = useSelector(
    (state) => state.dmaProcessSpecification || { data: null, isLoading: false }
  );

  useEffect(() => {
    if (token) {
      dispatch(fetchThresholdTypes({ token }))
        .unwrap()
        .then((response) => {
          const typeIds = response.data.reduce((acc, type) => {
            acc[type.name] = type.id;
            return acc;
          }, {});
          setThresholdTypeIds(typeIds);
        })
        .catch((error) => {
          console.error("Failed to fetch threshold types:", error);
        });
    } else {
      console.error("Token is missing, cannot fetch threshold types.");
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (thresholdTypeIds?.Impact && thresholdTypeIds?.Financial) {
      Object.keys(thresholdTypeIds).forEach((key) => {
        dispatch(
          fetchThreshold({
            moduleId,
            thresholdTypeId: thresholdTypeIds[key],
            token,
          })
        )
          .then((response) => {
            const thresholdData = response.payload.data;
            setThresholds((prev) => ({
              ...prev,
              [key]: parseFloat(thresholdData?.threshold) || 0,
            }));
            setInitialThresholds((prev) => ({
              ...prev,
              [key]: parseFloat(thresholdData?.threshold) || 0,
            }));
          })
          .catch((error) => {
            console.error(`Error fetching threshold for ${key}:`, error);
          });
      });
    }
  }, [thresholdTypeIds, moduleId, token, dispatch]);

  useEffect(() => {
    if (responseData?.success && responseData?.data) {
      const data = responseData.data;
      const impactData = Object.values(data).find(
        (item) => item.thresholdType?.name === "Impact"
      );
      const financialData = Object.values(data).find(
        (item) => item.thresholdType?.name === "Financial"
      );
      if (impactData && financialData) {
        setThresholds({
          Impact: parseFloat(impactData?.threshold) || 0,
          Financial: parseFloat(financialData?.threshold) || 0,
        });
        setInitialThresholds({
          Impact: parseFloat(impactData?.threshold) || 0,
          Financial: parseFloat(financialData?.threshold) || 0,
        });
      }
    }
  }, [responseData]);

  const updateThresholdValue = (type, newThreshold) => {
    setThresholds((prevThresholds) => ({
      ...prevThresholds,
      [type]: newThreshold,
    }));
    setIsChanged(true);
  };

  const updateThreshold = () => {
    const thresholdData = Object.entries(thresholds).map(
      ([type, threshold]) => ({
        thresholdTypeId: thresholdTypeIds[type],
        threshold,
      })
    );

    thresholdData.forEach((item) => {
      dispatch(createUpdateThreshold({ moduleId, thresholdData: item, token }))
        .unwrap()
        .then((response) => {
          console.info("Update successful:", response);
        })
        .catch((error) => {
          console.error("Update failed:", error);
        });
    });
  };

  const handleSave = () => {
    updateThreshold();
    setIsChanged(false);
  };

  const onCancel = () => {
    setThresholds(initialThresholds);
    setIsChanged(false);
  };

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState(false);

  // Whenever isError or errorMessage changes, reset showErrorModal
  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={localErrorMessage}
          handleButtonClick={
            isGenericError
              ? () => handleLogoutError(dispatch, navigate)
              : handleCancelErrorModal
          }
          deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
        />
      </div>
    );
  }

  return (
    <div>
      {isLoading && (
        <MotifProgressLoader className="loader" show variant="default" />
      )}
      <div className="threshold-container">
        {["Impact", "Financial"].map((type) => (
          <ThresholdCard
            key={type}
            typeId={type}
            sliderHeader={language[`${type}HeaderSlider`]}
            sliderDescription={language[`${type}Description`]}
            thresholdToInput={thresholds[type]}
            updateThresholdValue={(threshold) =>
              updateThresholdValue(type, threshold)
            }
          />
        ))}
      </div>
      <div className="modal-footer">
        <MotifButton onClick={onCancel} className="modal-cancel-button">
          {t("Cancel")}
        </MotifButton>
        <MotifButton
          className="modal-done-button"
          onClick={handleSave}
          disabled={!isChanged}
        >
          {t("Save")}
        </MotifButton>
      </div>
    </div>
  );
};

export default Thresholds;
