import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifFormField,
  MotifErrorMessage,
  MotifMessage,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./DmaModal.scss";

const LikelihoodModal = ({
  onClose,
  setName,
  handleLikelihoodValueClick,
  modalMode,
  NameLabel,
  LikelihoodLabel,
  DefinitionLabel,
  cancelButtonLabel,
  saveButtonLabel,
  updateButtonLabel,
  selectedRow,
  InputName,
  InputLikelihood,
  onDeleteClick,
  DeleteLabel,
}) => {
  const [nameInput, setNameInput] = useState("");
  const [likelihood, setLikelihood] = useState("");
  const [definition, setDefinition] = useState("");

  const [nameError, setNameError] = useState("");
  const [likelihoodError, setLikelihoodError] = useState("");
  const [definitionError, setDefinitionError] = useState("");

  useEffect(() => {
    if (modalMode === "edit" && selectedRow) {
      setNameInput(selectedRow.likelihoodLabel || "");
      setLikelihood(selectedRow.rating || "");
      setDefinition(selectedRow.likelihoodDefinition || "");
    }
  }, [modalMode, selectedRow]);

  const isEditMode = modalMode === "edit";

  const validateFields = () => {
    let isValid = true;

    if (!nameInput) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    if (!likelihood) {
      setLikelihoodError("Likelihood is required");
      isValid = false;
    } else if (isNaN(likelihood)) {
      setLikelihoodError("Likelihood must be a numeric value");
      isValid = false;
    } else if (parseFloat(likelihood) > 1) {
      setLikelihoodError("Likelihood must be less than or equal to 1");
      isValid = false;
    } else {
      setLikelihoodError("");
    }

    if (!definition) {
      setDefinitionError("Definition is required");
      isValid = false;
    } else {
      setDefinitionError("");
    }

    return isValid;
  };

  const handleSaveClick = () => {
    if (validateFields()) {
      const likelihoodData = {
        name: nameInput,
        likelihood,
        definition,
      };
      handleLikelihoodValueClick(likelihoodData);
    }
  };

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{setName}</h2>
        </div>
        <div className="modal-body">
          <div className="form-row">
            <div className="form-group">
              <MotifMessage id="form-title"> {NameLabel}*</MotifMessage>
              <MotifFormField>
                <MotifLabel
                  id="select-dma-name-input-label"
                  position="in"
                  htmlFor="name-input"
                >
                  {InputName}
                </MotifLabel>
                <MotifInput
                  data-testid="name-input"
                  className="session-modal-input session-name-input"
                  aria-describedby="select-dma-name-input-label"
                  labelPosition="in"
                  hideClearButton={true}
                  value={nameInput}
                  onChange={(e) => setNameInput(e.target.value)}
                />
              </MotifFormField>
              {nameError && <MotifErrorMessage>{nameError}</MotifErrorMessage>}
            </div>
            <div className="form-group">
              <MotifMessage id="form-title"> {LikelihoodLabel}*</MotifMessage>
              <MotifFormField>
                <MotifLabel
                  id="select-dma-name-input-label"
                  position="in"
                  htmlFor="name-input"
                >
                  {InputLikelihood}
                </MotifLabel>
                <MotifInput
                  id="likelihood-input"
                  data-testid="likelihood-input"
                  className="session-modal-input session-name-input"
                  aria-describedby="select-dma-name-input-label"
                  labelPosition="in"
                  hideClearButton={true}
                  value={likelihood}
                  onChange={(e) => setLikelihood(e.target.value)}
                />
              </MotifFormField>
              {likelihoodError && (
                <MotifErrorMessage>{likelihoodError}</MotifErrorMessage>
              )}
            </div>
          </div>
          <MotifMessage htmlFor="description-input">
            {DefinitionLabel}*
          </MotifMessage>
          <TextField
            id="description-input"
            data-testid="description-input"
            placeholder=""
            multiline
            fullWidth
            rows={4}
            value={definition}
            onChange={(e) => setDefinition(e.target.value)}
          />
          {definitionError && (
            <MotifErrorMessage>{definitionError}</MotifErrorMessage>
          )}
        </div>

        <div className="dma-modal-footer">
          <div className="left-buttons">
            {isEditMode && (
              <MotifButton
                className="modal-delete-button"
                onClick={onDeleteClick}
              >
                {DeleteLabel}
              </MotifButton>
            )}
          </div>
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="modal-cancel-button">
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton
              onClick={handleSaveClick}
              className="modal-done-button"
            >
              {isEditMode ? updateButtonLabel : saveButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

LikelihoodModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  setName: PropTypes.string.isRequired,
  handleLikelihoodValueClick: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  NameLabel: PropTypes.string.isRequired,
  LikelihoodLabel: PropTypes.string.isRequired,
  DefinitionLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  selectedRow: PropTypes.object,
  InputName: PropTypes.string.isRequired,
  InputLikelihood: PropTypes.number.isRequired,
  DeleteLabel: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
};

export default LikelihoodModal;
