import React from "react";
import { MotifLabel, MotifButton } from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import "./DeleteModal.scss";

const DeleteModal = ({
  onClose,
  setName,
  handleDeleteClick,
  cancelButtonLabel,
  deleteButtonLabel,
  labelText,
}) => {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{setName}</h2>
        </div>
        <div className="modal-body">
          <MotifLabel>{labelText}</MotifLabel>
        </div>
        <div className="modal-footer">
          <MotifButton onClick={onClose} className="modal-cancel-button">
            {cancelButtonLabel}
          </MotifButton>
          <MotifButton
            onClick={handleDeleteClick}
            className="button delete-modal-button"
          >
            {deleteButtonLabel}
          </MotifButton>
        </div>
      </div>
    </div>
  );
};

DeleteModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  setName: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  deleteButtonLabel: PropTypes.string.isRequired,
  labelText: PropTypes.string.isRequired,
  handleDeleteClick: PropTypes.func.isRequired,
};

export default DeleteModal;
