import React, { useState, useEffect, useMemo, useCallback } from "react";
import WaterfallChart from "../../../components/charts/WaterfallChart";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getMaterialIdentifiedImpactRiskOpportunity } from "../../../features/slices/DMAReporting";
import { MotifProgressLoader } from "@ey-xd/motif-react";
import { useTranslation } from "react-i18next";
import "./DMAReporting.scss"; // Import the stylesheet

const getCookie = (name) => {
  const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
  return match ? match[2] : null;
};

const DMAReporting = () => {
  const [isLoading, setIsLoading] = useState(true);
  const { moduleId } = useParams();
  const dispatch = useDispatch();

  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const { t } = useTranslation();

  // State for each data type
  const [impactData, setImpactData] = useState([]);
  const [materialImpactData, setMaterialImpactData] = useState([]);
  const [riskOpportunityData, setRiskOpportunityData] = useState([]);
  const [materialRiskOpportunityData, setMaterialRiskOpportunityData] =
    useState([]);
  const [error, setError] = useState(null);

  // Fetch data on component mount
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await dispatch(
          getMaterialIdentifiedImpactRiskOpportunity({ moduleId, token })
        );

        const data = response?.payload?.data;
        if (data) {
          setImpactData(data.identifiedImpacts || []);
          setMaterialImpactData(data.materialImpacts || []);
          setRiskOpportunityData(data.identifiedRiskAndOpportunities || []);
          setMaterialRiskOpportunityData(
            data.materialRiskAndOpportunities || []
          );
        } else {
          setError("No data found.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Failed to fetch data. Please try again.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, moduleId, token]);

  // Memoize chart data
  const memoizedImpactData = useMemo(() => impactData, [impactData]);
  const memoizedMaterialImpactData = useMemo(
    () => materialImpactData,
    [materialImpactData]
  );
  const memoizedRiskOpportunityData = useMemo(
    () => riskOpportunityData,
    [riskOpportunityData]
  );
  const memoizedMaterialRiskOpportunityData = useMemo(
    () => materialRiskOpportunityData,
    [materialRiskOpportunityData]
  );

  const ChartRow = useCallback(
    ({ title1, title2, data1, data2 }) => (
      <div className="motif-row dma-reporting-row">
        <div className="motif-col-6 motif-col-xl-6 chart-container border-box">
          <h3 className="chart-title">{title1}</h3>
          <WaterfallChart data={data1} />
        </div>
        <div className="motif-col-6 motif-col-xl-6 chart-container border-box">
          <h3 className="chart-title">{title2}</h3>
          <WaterfallChart data={data2} />
        </div>
      </div>
    ),
    []
  );

  return (
    <>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      {error && <div className="error-message">{error}</div>}
      {!isLoading && !error && (
        <div>
          <div className="reporting-button-container">
            <button>{t("Sustainability Matters list")}</button>
            <button>{t("IRO list")}</button>
            <button>{t("IRO mapping matrix")}</button>
            <button>{t("Support lists")}</button>
          </div>
          <ChartRow
            title1={t("IdentifiedImpactsByTopic")}
            title2={t("MaterialImpactsByTopic")}
            data1={memoizedImpactData}
            data2={memoizedMaterialImpactData}
          />
          <ChartRow
            title1={t("IdentifiedRiskandOpportunityByTopic")}
            title2={t("MaterialRiskandOpportunityByTopic")}
            data1={memoizedRiskOpportunityData}
            data2={memoizedMaterialRiskOpportunityData}
          />
        </div>
      )}
    </>
  );
};

export default DMAReporting;
