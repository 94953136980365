import React from "react";
import { MotifButton } from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import "./DmaModal.scss";

const ArModal = ({
  onClose,
  title,
  description,
  cancelButtonLabel,
  isDisabled,
}) => {
  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{title}</h2>
        </div>
        <div className="modal-body">
          <div>
            <div
              className="formatted-description"
              dangerouslySetInnerHTML={{ __html: description }}
            />
          </div>
        </div>
        <div className="modal-dga-footer">
          <div className="right-buttons">
            <MotifButton onClick={onClose} className="modal-cancel-button">
              {cancelButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

ArModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

export default ArModal;