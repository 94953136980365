import React, {
  useEffect,
  useReducer,
  useMemo,
  useCallback,
  useState,
} from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ContextSelection from "../../../../components/contextSelection/ContextSelection";
import ClientTable from "../../../../components/clientTable/ClientTable";
import CardDMS from "../../../../components/cardDMS/CardDMS";
import { Edit } from "../../../../constants/icons/Icons";
import { TableCell, IconButton, Box, Tooltip } from "@mui/material";
import GlModal from "../../../../components/modals/dmaModals/GlModal";
import Pagination from "../../../../components/paginationForCards/Pagination";
import TopBar from "../Topbar";
import DeleteModal from "../../../../components/modals/deleteModal/DeleteModal";
import ErrorModal from "../../../../components/modals/error/errorModal";
import {
  fetchAllGl,
  createGl,
  updateGl,
  deleteGl,
  clearError,
} from "../../../../features/slices/DMAGeographicalLocation";
import { MotifProgressLoader, MotifTruncate } from "@ey-xd/motif-react";
import "./GeographicalLocation.scss";
import { handleError as handleLogoutError } from "../../../../utils/handleError";

const initialState = {
  isGlTableView: true,
  isGlModalOpen: false,
  modalGlMode: "add",
  modalGlTitle: "",
  selectedGlRow: null,
  currentGlPage: 1,
  searchGlQuery: "",
  isGlDeleteModalOpen: false,
  glToDelete: null,
  glData: [],
};

const glReducer = (state, action) => {
  switch (action.type) {
    case "SET_VIEW":
      return { ...state, isGlTableView: action.payload };
    case "SET_MODAL":
      return {
        ...state,
        isGlModalOpen: action.payload.isOpen,
        modalGlMode: action.payload.mode,
        modalGlTitle: action.payload.title,
        selectedGlRow: action.payload.row,
      };
    case "SET_PAGE":
      return { ...state, currentGlPage: action.payload };
    case "SET_SEARCH_QUERY":
      return { ...state, searchGlQuery: action.payload };
    case "SET_DELETE_MODAL":
      return {
        ...state,
        isGlDeleteModalOpen: action.payload.isOpen,
        glToDelete: action.payload.row,
      };
    case "SET_GL_DATA":
      return { ...state, glData: action.payload };
    case "SET_GL_TO_DELETE":
      return { ...state, glToDelete: action.payload };
    case "SET_IS_GL_DELETE_MODAL_OPEN":
      return { ...state, isGlDeleteModalOpen: action.payload };
    default:
      return state;
  }
};

const GeographicalLocation = () => {
  const [state, dispatchState] = useReducer(glReducer, initialState);
  const { moduleId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getCookie = (name) => {
    const match = document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    return match ? match[2] : null;
  };
  const token =
    useSelector((state) => state.user.jwtToken) ||
    getCookie("authToken") ||
    sessionStorage.getItem("authToken");
  const { t } = useTranslation();
  const { isLoading, isError, errorMessage, isGenericError } = useSelector(
    (state) => state.allGldata
  );

  const fetchData = useCallback(async () => {
    try {
      const response = await dispatch(fetchAllGl({ moduleId, token })).unwrap();
      if (response?.data) {
        dispatchState({ type: "SET_GL_DATA", payload: response.data });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }, [dispatch, moduleId, token]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const {
    isGlTableView,
    isGlModalOpen,
    modalGlMode,
    modalGlTitle,
    selectedGlRow,
    currentGlPage,
    searchGlQuery,
    isGlDeleteModalOpen,
    glToDelete,
    glData,
  } = state;

  const handleEdit = useCallback((row) => {
    dispatchState({
      type: "SET_MODAL",
      payload: {
        isOpen: true,
        mode: "edit",
        title: "Edit geographical location",
        row,
      },
    });
    dispatchState({
      type: "SET_GL_TO_DELETE",
      payload: row,
    });
  }, []);

  const handleDelete = async () => {
    if (!glToDelete) return;

    try {
      const response = await dispatch(
        deleteGl({ glId: glToDelete.id, moduleId, token })
      );
      if (!response.error) {
        dispatchState({
          type: "SET_GL_DATA",
          payload: glData.filter((gl) => gl.id !== glToDelete.id),
        });
        fetchData();
      } else {
        console.error("Error deleting GL:", response);
      }
    } catch (error) {
      console.error("Error deleting GL:", error);
    } finally {
      dispatchState({
        type: "SET_DELETE_MODAL",
        payload: { isOpen: false, row: null },
      });
      dispatchState({
        type: "SET_MODAL",
        payload: { isOpen: false, row: null },
      });
    }
  };

  const openDeleteModal = useCallback(() => {
    dispatchState({ type: "SET_IS_GL_DELETE_MODAL_OPEN", payload: true });
  }, []);

  const handleSourceValueClick = async (glData) => {
    try {
      let response;
      if (modalGlMode === "add") {
        response = await dispatch(createGl({ moduleId, glData, token }));
        if (response.payload) {
          dispatchState({
            type: "SET_GL_DATA",
            payload: [...state.glData, response.payload],
          });
          fetchData();
        } else {
          console.error("Error creating GL:", response.error);
        }
      } else if (modalGlMode === "edit" && selectedGlRow) {
        response = await dispatch(
          updateGl({ glId: selectedGlRow.id, moduleId, glData, token })
        );
        if (response.payload) {
          dispatchState({
            type: "SET_GL_DATA",
            payload: state.glData.map((gl) =>
              gl.id === response.payload.id ? response.payload : gl
            ),
          });
          fetchData();
        } else {
          console.error("Error updating GL:", response.error);
        }
      }
      dispatchState({
        type: "SET_MODAL",
        payload: { isOpen: false, mode: "add", title: "", row: null },
      });
    } catch (error) {
      console.error("Error creating/updating GL:", error);
    }
  };

  const renderGlTableCell = useCallback(
    (colName, value, row, index) => {
      if (colName === "Action") {
        return (
          <TableCell key="actions" sx={{ textAlign: "right", width: "100px" }}>
            <Box display="flex" justifyContent="flex-end">
              <Tooltip title="Edit" placement="top">
                <IconButton onClick={() => handleEdit(row)}>
                  <Edit />
                </IconButton>
              </Tooltip>
            </Box>
          </TableCell>
        );
      }
      if (colName === "description") {
        return (
          <TableCell key={index}>
            <MotifTruncate expanded={true}>{value}</MotifTruncate>
          </TableCell>
        );
      }
      return <TableCell key={index}>{value}</TableCell>;
    },
    [handleEdit]
  );

  const generateRowKey = (row) => row.id;

  const handleOpenModal = (mode) => {
    dispatchState({
      type: "SET_MODAL",
      payload: {
        isOpen: true,
        mode,
        title:
          mode === "add"
            ? "New geographical location"
            : "Edit geographical location",
        row: null,
      },
    });
  };

  const handleCloseModal = () => {
    dispatchState({
      type: "SET_MODAL",
      payload: { isOpen: false, mode: "add", title: "", row: null },
    });
  };

  const handlePageChange = (page) => {
    dispatchState({ type: "SET_PAGE", payload: page });
  };

  const handleSearch = (event) => {
    dispatchState({ type: "SET_SEARCH_QUERY", payload: String(event) });
  };

  const getGlFilteredData = useMemo(() => {
    return glData.filter((item) =>
      item.name?.toLowerCase().includes(searchGlQuery.toLowerCase())
    );
  }, [glData, searchGlQuery]);

  const paginatedData = useMemo(() => {
    const startIndex = (currentGlPage - 1) * 10;
    return getGlFilteredData.slice(startIndex, startIndex + 10);
  }, [currentGlPage, getGlFilteredData]);

  const columnsGl = [
    { colName: "name", label: "Name", showSorting: true },
    {
      colName: "description",
      label: "Description",
      showSorting: true,
      width: "85em",
    },
    { colName: "Action", label: "Action", showSorting: false },
  ];

  const totalPages = Math.ceil(getGlFilteredData.length / 10);

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [localErrorMessage, setLocalErrorMessage] = useState(false);

  // Whenever isError or errorMessage changes, reset showErrorModal
  useEffect(() => {
    if (isError && errorMessage) {
      setLocalErrorMessage(errorMessage);
      setShowErrorModal(true);
    }
  }, [isError, errorMessage]);

  const handleCancelErrorModal = () => {
    setShowErrorModal(false);
    dispatch(clearError());
  };

  if (showErrorModal && localErrorMessage) {
    return (
      <div>
        <ErrorModal
          setName={t("Error")}
          labelText={localErrorMessage}
          handleButtonClick={
            isGenericError
              ? () => handleLogoutError(dispatch, navigate)
              : handleCancelErrorModal
          }
          deleteButtonLabel={isGenericError ? t("Reload") : t("Cancel")}
        />
      </div>
    );
  }

  return (
    <div>
      {isLoading && (
        <MotifProgressLoader
          data-testid="loading-spinner"
          className="loader"
          show
          variant="default"
        />
      )}
      <ContextSelection
        addButtonText="Add New"
        onClickNewProject={() => handleOpenModal("add")}
        onChangeSearchInput={handleSearch}
      />
      <TopBar
        isTableView={isGlTableView}
        totalResults={glData.length}
        filteredResults={getGlFilteredData.length}
        onTableViewClick={() =>
          dispatchState({ type: "SET_VIEW", payload: true })
        }
        onCardViewClick={() =>
          dispatchState({ type: "SET_VIEW", payload: false })
        }
      />
      <div className="gl-card-table-switch">
        {isGlTableView ? (
          <ClientTable
            columns={columnsGl}
            data={getGlFilteredData}
            itemsPerPage={5}
            renderTableCell={renderGlTableCell}
            generateRowKey={generateRowKey}
          />
        ) : (
          <div className="card-container">
            {paginatedData.map((item) => (
              <CardDMS
                key={item.id}
                header={item.name}
                pageName="GeographicalLocation"
                descriptionHeaderText={item.description}
                handleEdit={() => handleEdit(item)}
              />
            ))}
          </div>
        )}
      </div>
      {!isGlTableView && (
        <Pagination
          totalPages={totalPages}
          currentPage={currentGlPage}
          onPageChange={handlePageChange}
        />
      )}
      {isGlModalOpen && (
        <GlModal
          onClose={handleCloseModal}
          title={modalGlTitle}
          handleSourceValueClick={handleSourceValueClick}
          modalMode={modalGlMode}
          NameLabel={t("Name")}
          InputName={t("EnterName")}
          DescriptionLabel={t("Description")}
          cancelButtonLabel={t("Cancel")}
          saveButtonLabel={t("AddToList")}
          updateButtonLabel={t("Update")}
          selectedRow={selectedGlRow}
          onDeleteClick={openDeleteModal}
          DeleteLabel={t("DeleteLabel")}
        />
      )}
      {isGlDeleteModalOpen && (
        <DeleteModal
          isOpen={isGlDeleteModalOpen}
          onClose={() =>
            dispatchState({
              type: "SET_DELETE_MODAL",
              payload: { isOpen: false, row: null },
            })
          }
          setName={t("DeleteLabel")}
          labelText={t("DeletConfirmationSources")}
          onCancel={() =>
            dispatchState({
              type: "SET_DELETE_MODAL",
              payload: { isOpen: false, row: null },
            })
          }
          handleDeleteClick={handleDelete}
          cancelButtonLabel={t("Cancel")}
          deleteButtonLabel={t("DeleteLabel")}
        />
      )}
    </div>
  );
};

export default GeographicalLocation;
