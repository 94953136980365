import React, { useState, useEffect } from "react";
import {
  MotifLabel,
  MotifButton,
  MotifInput,
  MotifErrorMessage,
  MotifFormField,
  MotifMessage,
} from "@ey-xd/motif-react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import "./DmaModal.scss";

const GlModal = ({
  onClose,
  title,
  handleSourceValueClick,
  modalMode,
  NameLabel,
  DescriptionLabel,
  cancelButtonLabel,
  saveButtonLabel,
  updateButtonLabel,
  selectedRow,
  onDeleteClick,
  DeleteLabel,
  InputName,
  isDisabled,
}) => {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");

  // Maximum character limit
  const MAX_CHAR_LIMIT = 3000;

  useEffect(() => {
    if (modalMode === "edit" && selectedRow) {
      setName(selectedRow.name);
      setDescription(selectedRow.description);
    } else {
      setName("");
      setDescription("");
    }
  }, [modalMode, selectedRow]);

  const validateFields = () => {
    let isValid = true;
    if (!name) {
      setNameError("Name is required");
      isValid = false;
    } else {
      setNameError("");
    }

    if (description.length >= MAX_CHAR_LIMIT) {
      setDescriptionError(
        `Description should not exceed ${MAX_CHAR_LIMIT} characters`
      );
      isValid = false;
    } else {
      setDescriptionError("");
    }

    return isValid;
  };
  const handleSubmit = () => {
    if (validateFields()) {
      const glData = {
        name,
        description,
      };
      handleSourceValueClick(glData);
    }
  };

  const isEditMode = modalMode === "edit";

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-header">
          <h2>{title}</h2>
        </div>
        <div className="modal-body">
          <div>
            <MotifMessage id="form-title"> {NameLabel}*</MotifMessage>
            <MotifFormField>
              <MotifLabel
                id="select-dma-name-input-label"
                position="in"
                htmlFor="name-input"
              >
                {InputName}
              </MotifLabel>
              <MotifInput
                className="session-modal-input session-name-input"
                aria-labelledby="Name"
                aria-label={NameLabel}
                labelPosition="in"
                aria-describedby="session-name"
                hideClearButton={true}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              {nameError && <MotifErrorMessage>{nameError}</MotifErrorMessage>}
            </MotifFormField>
            <MotifMessage id="gl-select-label">{DescriptionLabel}</MotifMessage>
            <TextField
              placeholder=""
              multiline
              fullWidth
              rows={4}
              aria-label={DescriptionLabel}
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              error={!!descriptionError}
            />
            {descriptionError && (
              <MotifErrorMessage>{descriptionError}</MotifErrorMessage>
            )}
          </div>
        </div>
        <div className="dma-modal-footer">
          <div className="left-buttons">
            {isEditMode && (
              <MotifButton
                className="modal-delete-button"
                onClick={onDeleteClick}
              >
                {DeleteLabel}
              </MotifButton>
            )}
          </div>
          <div className="right-buttons">
            <MotifButton
              onClick={onClose}
              disabled={isDisabled}
              className="modal-cancel-button"
            >
              {cancelButtonLabel}
            </MotifButton>
            <MotifButton onClick={handleSubmit} className="modal-done-button">
              {isEditMode ? updateButtonLabel : saveButtonLabel}
            </MotifButton>
          </div>
        </div>
      </div>
    </div>
  );
};

GlModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  InputName: PropTypes.string.isRequired,
  handleSourceValueClick: PropTypes.func.isRequired,
  modalMode: PropTypes.string.isRequired,
  NameLabel: PropTypes.string.isRequired,
  DescriptionLabel: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  saveButtonLabel: PropTypes.string.isRequired,
  updateButtonLabel: PropTypes.string.isRequired,
  selectedRow: PropTypes.object,
  DeleteLabel: PropTypes.string.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
};

export default GlModal;
